












































































































































































































































































































































































































































































































































































@import '~@/assets/styles/components/tableWithVDeep';
@import '~@/assets/styles/components/modal-cadastro';

.cadastro-usuario::v-deep {
  .v-btn--contained{
    box-shadow: none !important;
  }
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;
  
    @media (min-width: 599px) {
      display: none;
    }
  }
  
  table tbody tr{
    cursor: pointer;
    // border: 1px solid #ccc;
  }
  .theme--light.v-data-table tbody tr.v-data-table__selected{
    background: #14800040;
    &:hover{
      background: #14800040 !important;
    }
  }
  table tbody tr.v-data-table__expanded__row {
    background: #f1f1f1;
  }
  table tbody tr.v-data-table__expanded.v-data-table__expanded__content {
    box-shadow: none;
    td.table-expend  {
    }
    box-shadow: none;
    padding-top: 0;
    .row {
      & > div {
        border-bottom: 1px solid #ccc;
        // border-top: 1px solid #ccc;
        // display: flex;
        // justify-content: center;
        // align-items: center;
      }
      background: #d9d9D9;
      &:nth-child(2n +1) {
        background:   #e9e9e9;
      }
      transition: 0.5;
      // border-bottom: 1px solid #ccc;
      &:hover{
        background: #F1F1F1;
      } 
    }
    .row.header-line {
      &.isDeleted {
        color: #E53935
      }
  
      margin-top: 0;
      margin-left: -15px !important;
      margin-right: -15px !important ;
      &:last-child{
        margin-bottom: 0px;
      }
    }
    td > .header {
      margin-top: 0px;
      cursor: hand;
      background:white !important;
      div {
        font-weight: bold;
      }
      &:hover{
        background: white !important;
        
      }
    }
    td> .row.header-line {
      margin: 0;
    }
  }
  .wrapper-footer button {
    // // height: 36px;
    // // border-radius: 10px;
    // &:disabled{
    //   cursor: initial;
    //   border: none;
    //   background:#ccc !important;
    //   span{
    //     color: #666 !important;
    //   }
    // }
    // background: #1cb4ff !important;
    // height: 40px !important;
    // border-radius: 5px !important;
  }
}

.range-date::v-deep {
  .v-btn {
    height: 38px !important;
    width: 100%;
  }
}

.select-status::v-deep {
  .v-input__slot {
    margin-bottom: 0 !important;
  }
}

.v-chip {
  border-radius: 50% !important;
}



